import React from 'react'
import { RecoilRoot } from 'recoil'
import { config } from '@fortawesome/fontawesome-svg-core'

import "@fortawesome/fontawesome-svg-core/styles.css"
import './src/index.scss'

config.autoAddCss = false
export const wrapRootElement = ({ element }) => {
  return (
    <RecoilRoot>
      {element}
    </RecoilRoot>
  )
}

/*export const onPreRouteUpdate = ({ location, prevLocation }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'onPreRouteUpdate',
    fromLocation: location.pathname,
    toLocation: prevLocation ? prevLocation.pathname : null
  })
}*/